<div
  class="topbar"
  [class]="'topbar--' + colorType"
  [class.set--no-bg]="topbar?.setNoBg"
  [class.set--small]="topbar?.small"
  [class.topbar--row]="topbar?.newRow && !fixed"
>
  <div class="topbar__bar" [class.topbar__bar--fixed]="fixed" [class.set--small]="topbar?.small">
    <div class="container-fluid topbar__bar__inner">
      <div class="topbar__logo-wrapper" [class.set--row]="topbar?.newRow">
        <h1 class="topbar__logo type-jumbo type--svg mb-0">
          <a class="topbar__logo__link" (click)="close.emit()" [routerLink]="'/'">
            <span class="sr-only">KCAP</span>
            <svg class="topbar__logo__svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 618 156">
              <defs />
              <path
                d="M226.567-.001c-50.647 0-83.58 31.616-83.58 78.416 0 47.01 30.637 77.585 82.744 77.586 41.477 0 68.78-19.553 77.951-51.793l-38.349-8.32c-4.381 19.345-17.928 30.161-38.769 30.161-27.514 0-44.189-18.93-44.189-48.048 0-29.746 16.466-48.258 43.562-48.258 21.47 0 35.018 11.232 38.77 31.201l38.769-6.863C297.014 20.8 268.669 0 226.567 0z"
              />
              <path
                d="M37.725 111.28l24.177-24.336 44.185 64.896h43.772l-60.86-89.234L146.525 4.16h-44.813L37.725 69.887V4.16H0v147.679h37.725V111.28z"
              />
              <path
                fill-rule="evenodd"
                d="M417.905 119.393h-66.07l-12.506 32.45h-39.393L362.257 4.16h45.227l62.32 147.68h-39.392l-12.507-32.447zm-9.795-25.585l-15.841-41.186a196.247 196.247 0 01-7.087-22.672h-.626a196.015 196.015 0 01-7.087 22.672l-15.841 41.186h46.482zM560.475 101.091C595.7 101.091 618 81.954 618 51.585c0-29.12-21.884-47.425-56.691-47.425h-80.244v147.685h37.725v-50.754h41.685zm-8.337-70.517c16.675 0 26.054 8.113 26.054 22.048 0 14.144-9.797 22.258-26.264 22.256h-33.14V30.574h33.35z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </h1>
        <h2
          appParallax
          [parallaxRatio]="'1'"
          [loadParallax]="true"
          [class.video--loaded]="videoLoaded"
          class="mb-0 type-jumbo topbar__logo type--page"
          [class.set--hidden]="fixed"
          *ngIf="topbar?.title"
        >
          <a [routerLink]="topbar?.routerLink" *ngIf="topbar?.routerLink" class="topbar__logo__link topbar__logo__subtitle">
            {{ topbar?.title }}
          </a>
          <span *ngIf="!topbar?.routerLink" class="topbar__logo__link topbar__logo__subtitle">{{ topbar?.title }}</span>
        </h2>
      </div>

      <button class="hamburger-toggle" (click)="toggle.emit()">
        <span [class.hamburger-toggle__lines--active]="menuActive" class="hamburger-toggle__lines"></span>
        <span class="sr-only">Toggle navigation</span>
      </button>
    </div>

    <!-- <ul class="sub-navigation container-fluid" *ngIf="submenuItems?.length">
      <li class="sub-navigation__item" *ngFor="let subpage of submenuItems">
        <a class="sub-navigation__item__inner" [routerLink]="subpage.url" [routerLinkActive]="'sub-navigation__item__inner--active'">
          {{ subpage.menuTitle || subpage.title }}
        </a>
      </li>
    </ul> -->
  </div>

  <nav class="topbar__nav" [class.topbar__nav--active]="menuActive">
    <ul class="nav-list">
      <li class="nav-list__item" style="--list-index: 1">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="'/purpose'"
          [routerLinkActive]="'nav-list__item__link__active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Purpose
        </a>
      </li>
      <li class="nav-list__item" style="--list-index: 2">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="'/people'"
          [routerLinkActive]="'nav-list__item__link__active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          People
        </a>
      </li>
      <li class="nav-list__item" style="--list-index: 3">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="'/projects'"
          [routerLinkActive]="'nav-list__item__link__active'"
        >
          Projects
        </a>
      </li>

      <li class="nav-list__item" style="--list-index: 4">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="'/news'"
          [routerLinkActive]="'nav-list__item__link__active'"
        >
          News
        </a>
      </li>
      <li class="nav-list__item" style="--list-index: 5">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="'/contact'"
          [routerLinkActive]="'nav-list__item__link__active'"
        >
          Contact
        </a>
      </li>
      <!-- <li [style]="'--list-index:' + (4 + i)" class="nav-list__item" *ngFor="let page of menuItems; let i = index">
        <a
          class="type-jumbo-xl text-decoration-none nav-list__item__link"
          (click)="toggle.emit()"
          [routerLink]="page.url"
          [routerLinkActive]="'nav-list__item__link__active'"
        >
          {{ page.menuTitle || page.title }}
        </a>
      </li> -->
    </ul>

    <div class="container-md button-group color-white topbar__nav__buttons">
      <a class="button color-white button--border" (click)="toggle.emit()" [routerLink]="'/search'" [attr.data-text]="'search'">
        <span>search</span>
      </a>

      <a class="button color-white button--border" (click)="toggle.emit()" [routerLink]="'/pressroom'" [attr.data-text]="'pressroom'">
        <span>pressroom</span>
      </a>
      <a class="button color-white button--border" (click)="toggle.emit()" [routerLink]="'/jobs'" [attr.data-text]="'jobs'">
        <span>jobs</span>
      </a>
    </div>

    <footer class="topbar__nav__footer type-body-small">
      <div class="d-flex topbar__nav__footer__top">
        <div>
          <a class="button-link" (click)="toggle.emit()" [routerLink]="'/'">KCAP</a>
          &nbsp;&copy;{{ year }}
        </div>

        <ul class="topbar__nav__footer__list">
          <li class="topbar__nav__footer__list__item" *ngFor="let item of socialItems">
            <a rel="noopener" [href]="item.url" class="button-link" target="_blank">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <ul class="topbar__nav__footer__list">
        <li class="topbar__nav__footer__list__item" *ngFor="let item of footerItems">
          <a [routerLink]="item.url" (click)="toggle.emit()" class="button-link">{{ item.menuTitle || item.title }}</a>
        </li>
        <li class="topbar__nav__footer__list__item">
          <button (click)="openCookie()" class="button-link">{{ 'cookie-settings' | fromDictionary }}</button>
        </li>
      </ul>
    </footer>
  </nav>
</div>
