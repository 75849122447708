<section class="slider" [class.set--cursor-arrow]="cursorArrow" [class.set--slider]="total > 1">
  <div
    class="slider__wrapper"
    (mouseenter)="cursor.visible = true"
    (mouseleave)="cursor.visible = false"
    (mousemove)="updateCursor($event)"
    (click)="total > 1 && cursor.visible ? moveSlider() : ''"
  >
    <ngx-glide
      #NgxGlideComponent
      [gap]="config.gap"
      [showArrows]="config.showArrows"
      [showBullets]="config.showBullets"
      [perView]="config.perView"
      [startAt]="config.startAt"
      listenToEvents="config.listenToEvents"
      [type]="'carousel'"
      (ranAfter)="updatePage($event)"
      (mountedAfter)="updatePage($event)"
      (mountedBefore)="checkTotalSlides()"
    >
      <ng-content></ng-content>
    </ngx-glide>
  </div>
  <div class="slider__page" *ngIf="total > 1">
    <strong>{{ currentPage + 1 }} / {{ total }}</strong>
  </div>

  <div class="slider__cursor" *ngIf="cursor.visible && total > 1 && cursorArrow" [style.left.px]="cursor.x" [style.top.px]="cursor.y">
    <div class="slider__cursor__pointer type--{{ cursor.direction }}"></div>
  </div>
</section>
