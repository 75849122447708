import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';
import { Store, State } from '@ngrx/store';
import * as actions from '../../../store/actions';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() imageAnchor: string;
  @Input() imageSizes = { w: 1920, h: 960 };
  @Input() title: string;
  // @Input() meta: string;
  @Input() subtitle: string;
  @Input() intro: string;
  @Input() cssClass: string;
  @Input() videoBg: string;
  @Input() button: any;
  @Input() inverted = false;
  @Input() imageContain = false;
  @Input() fixedImage = false;
  @Input() animationOneTime = true; // bool when animation always start inviewport
  @Input() asVideoPlayer = false;
  @Input() playerVars: Html5PlayerVars = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
  };
  @Input() parallaxRatio = '-0.999';
  @Output() inView = new EventEmitter<any>();
  @Input() lazyLoading = 'lazy'; // 'lazy | eager'
  videoPlayed = false; // bool when video is playing
  animPlay = false; // starts firing animation
  inviewPort = false;
  fstrPlayer: any;
  videoLoaded = false;
  @Input() isBrowser = false;

  @ViewChild('header') el: ElementRef;

  constructor(private cd: ChangeDetectorRef, private store: Store<any>, @Inject(PLATFORM_ID) private platformId) {}

  get removeHashFromString() {
    const link = this.button.url.split('#');

    return link[0];
  }

  get hashFromString() {
    const link = this.button.url.split('#');
    const checkLink = link.length > 1 ? link[1] : null;

    return checkLink;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  inViewChange(e: boolean) {
    this.inviewPort = e;
    this.inView.emit(e);

    if (!this.videoLoaded) {
      return;
    }

    if (e) {
      this.animPlay = true; // bool when animation always start inviewport

      if (this.videoBg && this.fstrPlayer && this.playerVars.autoplay) {
        this.fstrPlayer.play();
      }
      this.store.dispatch(actions.SetTheme({ theme: 'semi-light' }));
      if (!this.imageUrl) {
        this.store.dispatch(actions.SetTheme({ theme: 'dark' }));
      }
    } else {
      // starts title animation when nextime inviewport, when gewenst.
      if (!this.animationOneTime) {
        this.animPlay = false;
      }

      if (this.videoBg) {
        this.fstrPlayer.pause();
      }
      this.store.dispatch(actions.SetTheme({ theme: 'dark' }));
    }
  }

  playVideo() {
    this.fstrPlayer.playbackRate = 1;
    this.fstrPlayer.currentTime = 0;
    this.fstrPlayer.muted = false;
    this.fstrPlayer.controls = true;
    this.videoPlayed = true;
    this.fstrPlayer.play();
  }

  player($e) {
    // console.log('jep');
    this.fstrPlayer = $e.target;
    if (!this.videoLoaded && this.asVideoPlayer) {
      this.fstrPlayer.playbackRate = 0.8;
      this.fstrPlayer.currentTime = 3;
    }
    this.videoLoaded = true;
    this.fstrPlayer.setAttribute('playsinline', 'playsinline'); // for ios
    // console.log($e);
  }

  scrollDown() {
    const scrollHeight = this.el.nativeElement.offsetHeight;

    window.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  toggleAudio() {
    this.playerVars.muted = !this.playerVars.muted;
    this.fstrPlayer.muted = this.playerVars.muted;

    if (!this.playerVars.muted) {
      this.fstrPlayer.currentTime = 0;
    }
  }
}
