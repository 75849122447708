import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent implements OnInit {
  @Input() current = 0;
  @Input() showGallery = false;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.toggle.emit(true);
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.showGallery) {
      this.closeModal();
    }
  }
}
