<aside class="image-modal" [class.image-modal--open]="showGallery">
  <div class="image-modal__wrapper color-black">
    <div class="image-modal__content">
      <ng-content></ng-content>
      <!-- <ngx-glide
      *ngIf="showGallery"
      #NgxGlideComponent
      [gap]="5"
      [showArrows]="true"
      [showBullets]="images.length > 1"
      [perView]="1"
      [startAt]="current"
      listenToEvents="true"
    >
      <figure class="image-modal__image" *ngFor="let item of images">
        <img [src]="item.thumbnail | resize: 1600" />
        <figcaption *ngIf="item.title || item.caption">{{ item.title || item.caption }}</figcaption>
      </figure>
    </ngx-glide> -->
    </div>
  </div>

  <div class="image-modal__overlay"></div>
  <button class="image-modal__button color-black button-icon" (click)="closeModal()">
    <fstr-icon [anchor]="'icon-cross'"></fstr-icon>
    <span class="sr-text">close image modal</span>
  </button>
</aside>
