import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnInit {
  @Input() config = {
    gap: 0,
    showArrows: true,
    showBullets: true,
    perView: 1,
    startAt: 0,
    listenToEvents: true,
  };
  @Input() total = 0;
  @Input() cursorArrow = true;

  currentPage = 0;
  cursor = {
    x: 0,
    y: 0,
    visible: false,
    direction: 'left',
    scrolling: false,
  };

  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;
  constructor() {}

  updatePage(e) {
    this.currentPage = this.ngxGlide.getIndex();
  }

  moveSlider() {
    // console.log('click');
    if (this.cursor.direction === 'left') {
      this.ngxGlide.go('<');
    } else {
      this.ngxGlide.go('>');
    }
  }

  updateCursor(e: any) {
    // console.log(e.x, window.innerWidth);
    if (!this.cursorArrow) {
      this.cursor.visible = false;
      return;
    }

    const target = e.target;

    if (target.tagName === 'A') {
      this.cursor.visible = false;
    } else {
      this.cursor.visible = true;
    }

    if (this.cursor.visible && this.total > 1) {
      const halfWindow = window.innerWidth / 2;
      this.cursor.x = e.x;
      this.cursor.y = e.y;
      if (e.x > halfWindow) {
        this.cursor.direction = 'right';
      } else {
        this.cursor.direction = 'left';
      }
    }
  }

  // disable slider
  checkTotalSlides() {
    if (this.total < 2) {
      this.ngxGlide.disable();
    }
  }
  ngOnInit() {}
}
