<div class="loading" *ngIf="loading">
  <div class="loading__indicator type-spinner" [class.is--active]="loading">
    <span class="loading__indicator__item is--bounce1">L</span>
    <span class="loading__indicator__item is--bounce2">O</span>
    <span class="loading__indicator__item is--bounce3">A</span>
    <span class="loading__indicator__item is--bounce4">D</span>
    <span class="loading__indicator__item is--bounce5">I</span>
    <span class="loading__indicator__item is--bounce6">N</span>
    <span class="loading__indicator__item is--bounce7">G</span>
  </div>
</div>
