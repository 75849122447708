import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryState } from '@teamfoster/sdk/dictionary-ngrx';
import { ContentPage } from '../../../content/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  @Input() menuItems: ContentPage[];
  @Input() socialItems;
  @Input() theme: string;

  constructor(private store: Store<DictionaryState>) {}

  ngOnInit() {}

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
