<article class="news-card card card--hover-black">
  <div class="card__inner news-card__inner">
    <div>
      <figure class="card__figure news-card__figure">
        <a [title]="newsItem.title" [routerLink]="newsItem.routerLink" class="image-link text-decoration-none">
          <app-image
            *ngIf="newsItem.imageUrl"
            [src]="newsItem.imageUrl"
            loading="lazy"
            (loaded)="imageLoaded = $event"
            [alt]="newsItem.title"
            [width]="824"
            [height]="623"
            [anchor]="newsItem.imageAnchor"
            [imageSizes]="[500, 800, 1020]"
          ></app-image>
        </a>
        <a [routerLink]="newsItem.routerLink" class="button color--white button--border card__figure__read-more">
          {{ 'newscard-more-link-title' | fromDictionary }}
        </a>
      </figure>
      <a [title]="newsItem.title" [routerLink]="newsItem.routerLink" class="text-decoration-none">
        <h1 class="card__title news-card__title">
          <span class="text">{{ newsItem.title }}</span>
          <span class="text-muted">{{ newsItem.updatedOn | date: 'dd-MM-yyyy' }}</span>
        </h1>
      </a>
    </div>
  </div>
</article>
