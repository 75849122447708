import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
})
export class ReversePipe implements PipeTransform {
  transform(value, bool = false) {
    if (!value) return;

    if (bool) {
      return value.reverse();
    }
    return value;
  }
}
