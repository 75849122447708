import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Directive, Input, ElementRef, HostListener, OnInit, AfterViewInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouterStateUrl } from '../../store';

@Directive({
  selector: '[appParallax]',
})
export class ParallaxDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() parallaxRatio = 1;
  @Input() loadParallax = false;

  initialTop = 0;
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private eleRef: ElementRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.router.events.pipe(takeUntil(this._unsubscribe$)).subscribe(event => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationStart) {
        //     this.eleRef.nativeElement.style.opacity = `0`;
      }

      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
        //         this.eleRef.nativeElement.style.opacity = `1`;
        setTimeout(() => {
          this.eleRef.nativeElement.style.transform = `translate3d(0, 0px, 0)`;
        }, 300);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      let topPos = 0;
      fromEvent(window, 'scroll').subscribe(a => {
        if (this.loadParallax) {
          // show scrollbar after windowinnerheight (window.innerHeight)
          topPos = this.initialTop - window.scrollY * this.parallaxRatio;

          // console.log(topPos);
          this.eleRef.nativeElement.style.transform = `translate3d(0, ${topPos}px, 0)`;
        }
      });

      this.eleRef.nativeElement.style.transform = `translate3d(0, ${topPos}px, 0)`;
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event) {
  //   if (this.loadParallax) {
  //     // this.eleRef.nativeElement.style.top = this.initialTop - window.scrollY * this.parallaxRatio + 'px';
  //     this.eleRef.nativeElement.style.transform = `translate3d(0, ${this.initialTop - window.scrollY * this.parallaxRatio}px, 0)`;
  //   }
  // }
}
