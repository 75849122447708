import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryState } from '@teamfoster/sdk/dictionary-ngrx';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ContentPage } from '../../../content/models';
import { TopbarConfig } from '../../../store/interfaces/topbar-config.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit, AfterViewInit {
  @Input() loading: boolean;
  @Input() menuActive: boolean;
  @Input() logoClass: string;
  @Input() topbar: TopbarConfig;
  @Input() menuItems: ContentPage[];
  // @Input() submenuItems: ContentPage[];
  @Input() footerItems: ContentPage[];
  @Input() socialItems: any;
  @Input() colorType: string;

  @Output() toggle = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @Output() open = new EventEmitter<void>();

  fixed = false;
  year = new Date().getFullYear();
  constructor(
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private store: Store<DictionaryState>
  ) {}

  ngOnInit() {
    // init
  }

  ngAfterViewInit() {
    fromEvent(window, 'scroll')
      .pipe(debounceTime(5))
      .subscribe(a => {
        const headerHeight = this.document.querySelector('.header.set--fixed-image')
          ? this.document.querySelector('.header').offsetHeight
          : 10;

        // show scrollbar after windowinnerheight (window.innerHeight)
        if (window.scrollY > headerHeight) {
          // if (window.scrollY > window.innerHeight / 2) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
        this.cd.detectChanges();
      });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.close.emit();
  }
}
