import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { News } from '../../../content/models';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent implements OnInit, AfterViewInit {
  @Input() newsItem: News;
  truncateTitle = 54;
  imageLoaded = false;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.checkViewport();
  }

  checkViewport() {
    if (window.innerWidth < 1440) {
      this.truncateTitle = 40;
      this.cd.detectChanges();
    } else {
      this.truncateTitle = 54;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkViewport();
  }
}
