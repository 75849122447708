<footer class="footer spacer-bottom-s" [class]="theme">
  <div class="container-xs">
    <span>
      <a class="button-link" [routerLink]="'/'">KCAP</a>
      &copy;{{ year }}
    </span>
    <ul class="footer__socials">
      <li class="footer__socials__item" *ngFor="let item of socialItems">
        <a rel="noopener" [href]="item.url" class="button-link" target="_blank">{{ item.title }}</a>
      </li>
    </ul>
  </div>
  <div class="container-xs">
    <ul class="d-flex footer__menu-list spacer-top-xxs">
      <li class="footer__menu-list__item" *ngFor="let item of menuItems">
        <a [routerLink]="item.url" class="button-link">{{ item.menuTitle || item.title }}</a>
      </li>
      <li class="footer__menu-list__item">
        <button (click)="openCookie()" class="button-link">{{ 'cookie-settings' | fromDictionary }}</button>
      </li>
    </ul>
  </div>
</footer>
